import React from "react";
import { Box, Typography, Container, Divider } from "@mui/material";
import mTheme from "../../moodyTheme";

const TermsOfUse: React.FC = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: "2rem 1rem",
        backgroundColor: mTheme.colors.moodyDarkBackground,
        color: mTheme.colors.moodyWhite,
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{ color: mTheme.colors.moodyBlue, fontWeight: "bold" }}
      >
        Terms of Use
      </Typography>
      <Typography variant="body1" gutterBottom>
        Effective Date: <strong>1.1.2025</strong>
      </Typography>
      <Divider sx={{ margin: "1rem 0", borderColor: "#4FC6DB" }} />

      <Typography variant="body1" paragraph>
        Welcome to Neptune! By accessing or using our application, you agree to
        comply with and be bound by these Terms of Use. Please read them
        carefully. If you do not agree to these terms, you must not use our
        application or services.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By using this application, you confirm that you are at least 17 years old or
        have the legal capacity to enter into a binding agreement. If you are
        using the application on behalf of a business or other entity, you represent
        that you have the authority to bind that entity to these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to update these Terms of Use at any time. Changes
        will be effective immediately upon posting. Your continued use of the
        application constitutes acceptance of the updated Terms.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Use of the application
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use the application only for lawful purposes and in accordance
        with these Terms. Specifically, you agree not to:
      </Typography>
      <ul>
        <li>
          Use the application in any way that violates applicable laws or
          regulations.
        </li>
        <li>
          Attempt to gain unauthorized access to any portion of the application or
          its systems.
        </li>
        <li>
          Engage in any activity that could damage, disable, or impair the
          application.
        </li>
      </ul>
      <Typography variant="body1" paragraph>
        We reserve the right to suspend or terminate your access to the application
        if you violate these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        User Accounts
      </Typography>
      <Typography variant="body1" paragraph>
        If you create an account on our application, you are responsible for
        maintaining the confidentiality of your account information and for all
        activities under your account. Notify us immediately if you suspect
        unauthorized use of your account.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        All content on this application, including text, images, graphics, logos,
        and software, is the property of Neptune or its licensors and is
        protected by copyright, trademark, and other intellectual property laws.
        You may not reproduce, distribute, or create derivative works from this
        content without our written permission.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Third-Party Links
      </Typography>
      <Typography variant="body1" paragraph>
        Our application may contain links to third-party applications. These links are
        provided for your convenience, and we are not responsible for the
        content or practices of these third-party sites.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Disclaimers
      </Typography>
      <Typography variant="body1" paragraph>
        The application and its content are provided "as is" without warranties of
        any kind, either express or implied. We do not guarantee that the
        application will be error-free or uninterrupted. We are not responsible for
        any loss or damage resulting from your use of the application.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the fullest extent permitted by law:
      </Typography>
      <ul>
        <li>
          Neptune will not be liable for any indirect, incidental, or
          consequential damages arising out of your use of the application.
        </li>
        <li>
          Our total liability for any claims related to the application will not
          exceed the amount you paid (if any) to use our services.
        </li>
      </ul>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to indemnify and hold harmless Neptune and its affiliates from
        any claims, damages, or expenses arising out of your use of the application
        or your violation of these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to terminate or suspend your access to the application
        at our sole discretion, without notice, for conduct that we believe
        violates these Terms or is otherwise harmful to us or other users.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms are governed by the laws of The State of Arizona. Any
        disputes will be resolved exclusively in the courts of The State of
        Arizona.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have questions or concerns about these Terms of Use, please
        contact us at:
      </Typography>
      <Typography variant="body1" paragraph>
        Neptune
        <br />
        Email:{" "}
        <a href="mailto:info@neptuneplatform.com">info@neptuneplatform.com</a>
      </Typography>
    </Container>
  );
};

export default TermsOfUse;

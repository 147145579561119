import React from "react";
import { Box, Typography, Container, Divider } from "@mui/material";
import mTheme from "../../moodyTheme";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: "2rem 1rem",
        backgroundColor: mTheme.colors.moodyDarkBackground,
        color: mTheme.colors.moodyWhite,
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{ color: mTheme.colors.moodyBlue, fontWeight: "bold" }}
      >
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom>
        Effective Date: <strong>1.1.2025</strong>
      </Typography>
      <Divider sx={{ margin: "1rem 0", borderColor: "#4FC6DB" }} />

      <Typography variant="body1" paragraph>
        At Neptune, we value your privacy and are committed to protecting your
        personal information. This Privacy Policy outlines how we collect, use,
        store, and protect your information when you visit our application and
        interact with our services.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We may collect the following types of information when you use our
        application:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>a. Personal Information</strong>
        <br />
        Name, email address, and other contact details provided voluntarily when
        you fill out forms or sign up as a beta tester.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>b. Non-Personal Information</strong>
        <br />
        IP address, browser type, operating system, and other technical details.
        Usage data, including the pages you visit, time spent on our application,
        and other analytical information.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use your information for the following purposes:
      </Typography>
      <ul>
        <li>To provide, operate, and improve our services.</li>
        <li>To respond to inquiries or provide customer support.</li>
        <li>To send promotional communications (with your consent).</li>
        <li>To analyze application usage and improve functionality.</li>
      </ul>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        How We Protect Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We implement industry-standard security measures to protect your
        information, including:
      </Typography>
      <ul>
        <li>Secure data encryption.</li>
        <li>Regular system monitoring and vulnerability checks.</li>
        <li>
          Restricted access to personal data to authorized personnel only.
        </li>
      </ul>
      <Typography variant="body1" paragraph>
        Despite these measures, no system is entirely secure. We encourage you
        to take precautions to protect your personal data.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Cookies and Tracking Technologies
      </Typography>
      <Typography variant="body1" paragraph>
        We use cookies and similar technologies to:
      </Typography>
      <ul>
        <li>Enhance user experience.</li>
        <li>Collect analytics to improve our application.</li>
        <li>Serve targeted advertisements, where applicable.</li>
      </ul>
      <Typography variant="body1" paragraph>
        You can manage your cookie preferences through your browser settings.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Sharing Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell or share your personal information with third parties,
        except in the following cases:
      </Typography>
      <ul>
        <li>To comply with legal obligations or respond to lawful requests.</li>
        <li>
          To trusted third-party service providers who assist in operating our
          application and delivering services, under strict confidentiality
          agreements.
        </li>
      </ul>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Your Rights
      </Typography>
      <Typography variant="body1" paragraph>
        Depending on your location, you may have the following rights:
      </Typography>
      <ul>
        <li>Access, correct, or delete your personal data.</li>
        <li>Withdraw consent to data processing.</li>
        <li>Opt-out of marketing communications.</li>
        <li>Lodge a complaint with your local data protection authority.</li>
      </ul>
      <Typography variant="body1" paragraph>
        To exercise these rights, contact us at{" "}
        <a href="mailto:info@neptuneplatform.com">info@neptuneplatform.com</a>.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Third-Party Links
      </Typography>
      <Typography variant="body1" paragraph>
        Our application may contain links to third-party applications. We are not
        responsible for the privacy practices of these sites and encourage you
        to review their policies.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Children’s Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our application is not intended for individuals under the age of 17. We do
        not knowingly collect information from minors. If you believe we have
        collected such information, please contact us to remove it.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy periodically to reflect changes in our
        practices or legal requirements. Please check this page regularly for
        updates.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </Typography>
      <Typography variant="body1" paragraph>
        Neptune
        <br />
        Email:{" "}
        <a href="mailto:info@neptuneplatform.com">info@neptuneplatform.com</a>
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;

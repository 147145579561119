import React from "react";
import { Box, Container, Grid, Typography, Avatar } from "@mui/material";
import ashleyImage from "../../assets/ashley.jpg";
import chrisImage from "../../assets/chris.jpg";
import annieImage from "../../assets/annie.jpg";
import nickImage from "../../assets/nick.jpg";
import tyler from "../../assets/tyler.jpg";
import shapesImage from "../../assets/shapes.png";
import linkedin from "../../assets/linkedin.png";
import scott from "../../assets/scott.jpg";
import kim from "../../assets/kim.jpg";
import amir from "../../assets/amir.jpeg";
import helena from "../../assets/helena.jpeg";
import timur from "../../assets/timur.jpg";
import rmImage from "../../assets/rm.png";
import theme from "../../moodyTheme";
import { link } from "fs";

const MeetTheTeam: React.FC = () => {
  const teamMembers = [
    {
      name: "Ashley Darling",
      title: "CEO",
      description:
        "As the visionary behind Neptune, Ashley leads the charge in redefining social media. With a keen eye for innovation and a deep commitment to empowering creators, she drives the overall strategy and business development. A lifelong musician and singer, Ashley brings her creative passion to every aspect of Neptune.",
      image: ashleyImage,
      link: "https://www.linkedin.com/in/ashley-darling-24126a241/",
      linkImage: linkedin,
    },
    {
      name: "R.M Easterly",
      title: "COO",
      description:
        "R.M. is an accomplished entrepreneur having founded 11 companies, including 7 successful exits. As COO, she brings unmatched expertise and leadership to day-to-day operations, ensuring the team stays focused and on track. Her ability to seamlessly balance this role while spearheading another venture speaks to her exceptional drive and organizational skill.",
      image: rmImage,
      link: "https://www.linkedin.com/in/reasterly/",
      linkImage: linkedin,
    },
    {
      name: "Timur Tugberk",
      title: "CHIEF MARKETING OFFICER",
      description:
        "Timur is a dynamic marketing leader, creative strategist, and design visionary with a career spanning tech, hospitality, fashion, and consumer brands. From launching renowned nightlife experiences to shaping the visual identity of major urban projects, he has built a reputation for blending storytelling, design, and community-driven marketing. As CMO of Neptune, Timur brings his signature flair for innovation, crafting a launch strategy that is as immersive, exclusive, and culture-shaping as the platform itself. With a keen eye for trends and a passion for making brands unforgettable, he’s setting the stage for Neptune to make a tidal wave in social media.",
      image:
        timur,
      link: "https://www.linkedin.com/in/timurdc/",
      linkImage: linkedin,
    },
    {
      name: "Chris Jones",
      title: "CTO & CLOUD ARCHITECT",
      description:
        "Chris is the mastermind behind technical efforts at Neptune. His expertise in building scalable and user-centric platforms ensures that Neptune’s infrastructure is robust, secure, and cutting-edge. Before getting into tech, Chris founded an independent bookstore. A talented musician himself, Chris is also as passionate about music as he is about technology.",
      image: chrisImage,
      link: "https://www.linkedin.com/in/thejonesjournal/",
      linkImage: linkedin,
    },
    {
      name: "Annie Pressman",
      title: "CPO & PROJECT LEAD",
      description:
        "Annie is the organizational force that keeps Neptune moving forward. She collaborates closely with Ashley to oversee all aspects of business development, ensuring that the team’s vision is executed smoothly. Her leadership and drive are key to Neptune’s success.",
      image: annieImage,
      link: "https://www.linkedin.com/in/anne-pressman/",
      linkImage: linkedin,
    },
    {
      name: "Nick Robinson",
      title: "CXO & CREATIVE DIRECTOR",
      description:
        "Nick is the creative genius behind Neptune’s user experience. As the Creative Director of our team, he crafts intuitive and engaging interfaces that make Neptune a joy to use. Nick focuses on designing a platform that is both beautiful and transformative.",
      image: nickImage,
      link: "https://www.linkedin.com/in/nick-c-robinson/",
      linkImage: linkedin,
    },
    {
      name: "Loc Le",
      title: "LEAD SOFTWARE ENGINEER",
      description:
        "Loc Le is a cloud software engineer specializing in AWS and scalable solutions. With experience in serverless applications, AI-driven solutions, and mentoring junior developers, Loc has been instrumental in building high-performance cloud systems. When he's not coding, Loc enjoys sports, sim racing, and exploring the latest advancements in technology.",
      image: "https://github.com/imlocle.png",
      link: "https://www.linkedin.com/in/imlocle",
      linkImage: linkedin,
    },
    {
      name: "Tyler Theis",
      title: "VP OF CYBERSECURITY",
      description:
        "Tyler is an expert in Cybersecurity with over a decade of experience building secure environments. A proud military veteran; Tyler has served in multiple missions vital to national security while also returning home to continue to serve in multiple types of environments as a civilian expert in both the private and public sectors. His vast technical experience combined with his passion for cybersecurity and emerging technologies like Blockchain and Artificial Intelligence will be vital to propelling Neptune to the forefront of innovation.",
      image: tyler,
      link: "https://www.linkedin.com/in/danieltheis/",
      linkImage: linkedin,
    },
    {
      name: "Kim Stockwell",
      title: "FULLSTACK SOFTWARE ENGINEER",
      description:
        "Kim specializes in software development with expertise in Java, Python, JavaScript, SQL, AWS, and Thingworx. With over a decade of experience, she has a proven record in team leadership, version control management, and delivering high-performance applications. Kim is highly skilled in troubleshooting, analysis, process improvement, and integrating systems to optimize business operations. In her downtime, she enjoys time with family, her two cats, cozy video games and keeping fit with martial arts.",
      image: kim,
      link: "https://www.linkedin.com/in/kimberly-stockwell-a1732b35/",
      linkImage: linkedin,
    },
    {
      name: "Scott Smith",
      title: "STAFF IOS ENGINEER",
      description:
"Scott brings a wealth of experience in iOS Engineering and dad-jokes to Neptune. He’s particularly passionate about making UI/UX come to life through code (you’ll feel it) and has a sharp eye for detail. Scott’s life has included illustration, skateboarding, snowboarding, wakeboarding, acoustic guitar singing-songwriting, fitness, a 2-year religious volunteer mission in Guatemala where he became fluent in Spanish, and much more. Most importantly, his life revolves around family—where his wife & son and 17 nieces & nephews each have his heart. And lastly, here’s a note from Scott: if you’re unsure about your facial hair, just give it some time; it’ll grow on you.",
      image: scott,
      link: "https://www.linkedin.com/in/scottsmithdev/",
      linkImage: linkedin,
    },
    {
      name: "Amir Ghezelbash",
      title: "SENIOR SOFTWARE ENGINEER",
      description:
        "Amir is a powerhouse engineer with two decades of experience building secure, scalable cloud-first applications. A specialist in Cloud, Microsoft Azure, and .NET frameworks, he’s led major modernization efforts across financial, healthcare, and enterprise clients. At Neptune, Amir brings deep DevOps expertise and a talent for automation, architecting systems that are both resilient and future-proof. Through streamlining CI/CD pipelines and leading high-impact cloud migrations, Amir makes complex infrastructure look effortless.",
        image: amir,
      link: "https://www.linkedin.com/in/amirgh/",
      linkImage: linkedin,
    },
    {
      name: "Helena Roberts",
      title: "CLOUD DEVOPS ENGINEER",
      description: 
          "Helena is a DevOps engineer with over a decade of experience building cloud infrastructure across financial, aerospace, medical, and student management sectors. With deep expertise in Terraform, Kubernetes, AWS, and Azure, Helena bridges development and operations with seamless precision. Whether automating deployment pipelines, managing infrastructure as code, or leading cross-functional initiatives, she ensures Neptune is ready to scale.", 
      image: helena,
      link: " https://www.linkedin.com/in/helena-r-3367903b/",
      linkImage: linkedin,
    }
   
  ];

  return (
    <Box
      id="meet-the-team"
      sx={{
        position: "relative",
        backgroundColor: theme.colors.moodyDarkBackground,
        padding: "6rem 2rem",
      }}
    >
      <Box
        component="img"
        src={shapesImage}
        alt="Shapes"
        sx={{
          position: "absolute",
          top: "100px",
          right: "100px",
          width: "150px",
          height: "auto",
          display: { xs: "none", md: "block" },
        }}
      />
      <Container maxWidth="lg">
        <Typography
          sx={{
            ...theme.darkHeaderStyles,
            textAlign: "left",
            fontSize: { xs: "30px", md: "48px" },
            lineHeight: { xs: "40px", md: "60px" },
            marginBottom: { xs: "2rem", md: "4rem" },
          }}
        >
          Crafting a New Digital World—
          <br /> Meet the Minds Behind Neptune
        </Typography>
        <Grid container spacing={4}>
          {teamMembers.map((member) => (
            <Grid item xs={12} sm={6} md={3} key={member.name}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "flex-start", md: "flex-start" },
                  textAlign: "left",
                }}
              >
                <Avatar
                  src={member.image}
                  alt={member.name}
                  sx={{
                    width: 166,
                    height: 166,
                    border: `5px solid ${theme.colors.moodyBlue}`,
                    marginBottom: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: theme.colors.moodyWhite,
                    lineHeight: "40px",
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "18px",
                    marginTop: "0.5rem",
                    fontWeight: "bold",
                    color: theme.colors.moodyBlue,
                    lineHeight: "30px",
                  }}
                >
                  {member.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    marginTop: "0.5rem",
                    fontWeight: "medium",
                    color: theme.colors.moodyWhite,
                    lineHeight: "28px",
                  }}
                >
                  {member.description}
                </Typography>
                <Box sx={{ marginTop: "1rem" }}>
                  <a
                    href={member.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      component="img"
                      src={member.linkImage}
                      alt={`${member.name}'s LinkedIn`}
                      sx={{ width: 30, height: 30 }}
                    />
                  </a>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MeetTheTeam;

import React, { useState } from "react";
import { Box } from "@mui/material";
import MeetTheTeam from "../components/sections/meetTheTeam";
import Hero from "./sections/heroSection";
import WhoWeAre from "./sections/whoWeAreSection";
import WtfNeptune from "./sections/wtfNeptune";
import FeatureHighlights from "./sections/featureHighlights";
import HowDifferent from "./sections/whyNeptune";
import FollowUs from "./sections/followUs";
import JoinList from "./sections/joinList";
import Footer from "./footer";
import TopAppBar from "./TopAppBar";
import Login from "./Login";

const MainPage: React.FC = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  // if (!isLoggedIn) {
  //   return <Login onLogin={handleLogin} />;
  // }

  return (
    <>
      <TopAppBar />
      <Box
        sx={{
          width: "100vw",
          margin: 0,
          padding: 0,
        }}
      >
        <Box sx={{ padding: { xs: "0rem", md: "0rem" } }}>
          <Hero />
          <WhoWeAre />
          <MeetTheTeam />
          <WtfNeptune />
          <FeatureHighlights />
          <HowDifferent />
          <FollowUs />
          {/* <JoinList /> */}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default MainPage;
